<template>
  <div class="vx-row mb-6" style="width:70%">
    <div class="vx-col sm:w-1/4 w-full flex items-center">
      <span>Payment Type</span>
    </div>
    <div class="vx-col sm:w-3/4 w-full">
      <multiselect
        v-model="selected"
        :options="options"
        placeholder="Type to search"
        :max-height="125"
        track-by="name"
        :disabled="status_disabled"
      >
        <span slot="noResult">Oops! No data found</span>
        <template slot="singleLabel" slot-scope="props">
          <span class="option__desc">
            <span class="option__title">{{ props.option.name }}</span>
          </span>
        </template>

        <template slot="option" slot-scope="props">
          <div class="option__desc">
            <span class="option__title">{{ props.option.name }}</span>
          </div>
        </template>
      </multiselect>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      options: [{code: "", name: "All"}, {code: "Cash", name: "Cash"}, {code: "Credit", name: "Credit"}],
      selected: {code: "", name: "All"},
    };
  },
  methods: {},
  watch: {
    selected(v) {
      this.selected = v;
      if (v) {
        this.$emit("data", v.code);
      } else {
        this.$emit("data", v.code);
      }
    },
  },
  mounted() {},
};
</script>  